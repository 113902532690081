/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Bravo = {
    // All pages
    'common': {
      init: function() {


        // Handle Aria hidden attr toggle
        function toggleAriaHidden(target) {
          $(target).attr('aria-hidden', function (i, attr) {
            return attr === 'true' ? 'false' : 'true';
          });
        }

        // Handle Aria expanded attr toggle
        function toggleAriaExpanded(target) {
          $(target).attr('aria-expanded', function (i, attr) {
            return attr === 'true' ? 'false' : 'true';
          });
        }


        // Modernizr mq support
        var max_query = Modernizr.mq('(max-width: 768px)'),
            min_query = Modernizr.mq('(min-width: 768px)');




        /* Toggle menu function
         * Toggle Aria attributes and visibility classes.
         */

        function mobileMenu() {
          $( '.menu-toggle' ).on( 'click', function() {
            var nav = $('.nav-primary');

            // Toggle button active class
            $( this).toggleClass('active', '');

            $(nav).toggleClass('open');
            toggleAriaExpanded(nav);

            if($(nav).hasClass('open')) {
              $(nav).attr('tabindex', -1).focus();
            }
          } );
        }






        /*
         * Hijack the quick enquiry form link.
         * Toggle Aria attributes and visibility classes.
         *
         */
        function enquiryForm() {

          var formWrapper = $('#enquire-menu'),
              ctaBtn      = $('.js-enquiry-btn'),
              closeBtn    = $('.close-enquiry');


          // Set-up defaults
          formWrapper.attr('aria-hidden', 'true');
          ctaBtn.attr('aria-controls', '#enquire-menu');
          ctaBtn.attr('aria-expanded', 'false');


          // On button click
          ctaBtn.on('click', function(e){
            // prevent href action
            e.preventDefault();

            // Toggle classes and attributes
            $(formWrapper).toggleClass('open');
            toggleAriaHidden(formWrapper);
            toggleAriaExpanded(ctaBtn);

            // Provide focus for keyboard users
            if($(formWrapper).hasClass('open')) {
              $(formWrapper).attr('tabindex', -1).focus();
            }

          });

          // Close button click
          $(closeBtn).on('click', function(){
            // Toggle attributes
            $(formWrapper).removeClass('open');
            $(ctaBtn).attr('aria-expanded', 'false');
            toggleAriaHidden(formWrapper);
          });
        }



        /*
         * Function to fix sidebar when scrolling
         *
         */
        function fix_sidebar() {

          var sidebar = $('.trip-info');

          function reset() {
            sidebar.css({'position' : '', 'width': '', 'top': ''});
          }

          // If it exists...
          if( $(sidebar).length ){

            // Get current position and width
            var default_sidebar_position = sidebar.offset().top,
                default_sidebar_width = sidebar.outerWidth(),
                window_position = 0;

            // On scroll...
            $(window).scroll(function () {

              // Grab current window position
              window_position = $(window).scrollTop();

              // If we scroll past original position,
              // fix the sidebar
              if (window_position >= default_sidebar_position) {
                sidebar.css({'position': 'fixed', 'top': 0, 'width': default_sidebar_width});
              }
              else if (window_position < default_sidebar_position) {
                reset();
              }

            }).trigger('scroll');
          }

        }



        // Large photo gallery
        if( $('.photo-gallery').length || $('.mini-photo-gallery').length ) {

          // The big 'n
          $('.photo-gallery').each(function(){
            $('.gallery--thumbs').slick({
              slidesToShow: 6,
              slidesToScroll: 1,
              asNavFor: '.gallery--full',
              centerMode: true,
              focusOnSelect: true,
              arrows: true,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 4,
                    centerPadding: '10px'
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 3,
                    centerPadding: '10px'
                  }
                }
              ]
            });

            $('.gallery--full').slick({
              slidesToShow: 1,
              fade: true,
              slidesToScroll: 1,
              arrows: false,
              asNavFor: '.gallery--thumbs',
              adaptiveHeight: true
            });
          });


          // The small 'n's
          $('.mini-photo-gallery').each(function(){
            $('.mini-gallery').slick({
              slidesToShow: 1,
              fade: true,
              slidesToScroll: 1,
              arrows: true,
              adaptiveHeight: false,
              dots: true
            });
          });
        }



        // Fire off some stuff

        if (max_query) {
          mobileMenu();
        }

        if (min_query) {
          fix_sidebar();
        }


        enquiryForm();




      },
      finalize: function() {

        /*
         * Smooth scroll function for selected anchor links
         *
         * Prevents fragment id showing in url.
         */
        function hash_scroll() {

          var scroll_button = $('.scroll-to');

          if (scroll_button.length) {
            $(scroll_button).on('click', function (e) {
              // Get the fragment id
              var hash = $(this).attr('href').replace(/^.*#/, '');

              // Scroll
              $('body, html').stop().animate({'scrollTop': $('#' + hash).offset().top - 95}, 500);
              e.preventDefault();
            });
          }
        }

        hash_scroll();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Bravo;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
